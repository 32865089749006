.cta {

	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;
}

.cta-inner {
	.cta-content {
		display: flex;
		align-items: center;

		&>* {
			flex-grow: 1;
			flex-basis: 0;
		}

		.cta-text {
			align-self: flex-start;
		}

		.cta-action {
			display: flex;

			div {
				padding-right: 24px;

				&:last-of-type {
					padding-right: 0;
				}
			}
		}
	}
}

@include media('>medium') {

	.cta {

		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}
	}

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}
	}
}

@include media('<medium') {
	.cta {
		.cta-content {
			flex-direction: column;

			.cta-text {

				h3,
				p {
					text-align: left;
				}

				.cta-action {
					display: flex;
					flex-direction: column;

					div {
						padding-right: 0;
					}

					.cta-input {
						&:first-of-type {
							margin-bottom: 20px;
						}
					}
				}

				.checkboxes {
					display: flex;
					flex-direction: column;
					text-align: left;
				}
			}
		}
	}
}