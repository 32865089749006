.features-split {
	.image-container {
		position: relative;
		height: $features-split-image--height__mobile;
		background-image: url(../../../images/illustration-our-partners.png);
		background-repeat: no-repeat;
		background-size: 100%;

		.tag {
			position: absolute;
			bottom: -$features-split-image--height__mobile / 3;
			left: $features-split-image--height__mobile / 3;
			background-color: get-color(light, 1);
			color: get-color(light, 1);
			padding-left: $features-split-image--height__mobile / 4;
			padding-right: $features-split-image--height__mobile / 4;
			max-width: $features-split-image--height__mobile + $features-split-image--height__mobile / 2;
			text-align: center;
			border-radius: 12px;
		}
	}
}

.section-inner {
	padding-top: $features-split--padding-t__mobile;
	padding-bottom: $features-split--padding-b__mobile;
}

.section-header {
	padding-bottom: $features-split-header--padding__mobile;
}

@include media('>medium') {

	.features-split {
		.image-container {
			height: $features-split-image--height__desktop;

			.tag {
				bottom: -$features-split-image--height__desktop / 8;
				left: $features-split-image--height__desktop / 3;
				padding-left: $features-split-image--height__desktop / 4;
				padding-right: $features-split-image--height__desktop / 4;
				max-width: $features-split-image--height__desktop + $features-split-image--height__desktop / 5;
			}
		}

		.section-inner {
			padding-top: $features-split--padding-t__desktop;
			padding-bottom: $features-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $features-split-header--padding__desktop;
		}
	}
}

@include media('<medium') {
	.features-split {
		padding-bottom: 60px;
	}
}