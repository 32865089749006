.community {

  .container-xs {
    margin-bottom: 60px;
  }

  .community-content {
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      margin-right: 30px;
    }

    .community-column {
      display: flex;
      flex-direction: column;

      &>img {
        margin-bottom: 30px;
      }
    }

    .community-row {
      display: flex;
      justify-content: space-between;
    }
  }
}

@include media('<medium') {
  .community {
    .community-content {
      flex-direction: column;

      div {
        margin-right: 0;
      }

      .community-column {
        flex-direction: row;

        img:first-of-type {
          padding-right: 10px;
        }

        &.multiple {
          flex-direction: column;

          img:first-of-type {
            padding-right: 0;
          }

          .community-row {
            margin-bottom: 30px;

            img:first-of-type {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}