.features-tiles-inner {
	.tiles-journey {
		display: flex;
		align-items: center;

		img {
			margin-bottom: 65%;
		}
	}
}

.tiles-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-right: -($tiles-items--padding__mobile * 0.5);
	margin-left: -($tiles-items--padding__mobile * 0.5);
	margin-top: -($tiles-items--padding__mobile * 0.5);

	&:last-of-type {
		margin-bottom: -($tiles-items--padding__mobile * 0.5);
	}

	&:not(:last-of-type) {
		margin-bottom: ($tiles-items--padding__mobile * 0.5);
	}

	&.push-left {

		&::after {
			content: '';
			flex-basis: $tiles-item--width;
			max-width: $tiles-item--width;
			box-sizing: content-box;
			padding-left: $tiles-items--padding__mobile * 0.5;
			padding-right: $tiles-items--padding__mobile * 0.5;
			height: 0;
		}
	}
}

.tiles-item {
	flex-basis: $tiles-item--width;
	max-width: $tiles-item--width;
	box-sizing: content-box;
	padding: $tiles-items--padding__mobile * 0.5;

	* {
		box-sizing: border-box;
	}
}

.tiles-item-inner {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 100%;
	padding: $tiles-item--inner-padding-v $tiles-item--inner-padding-h;
}

.features-tiles-item-header {
	.index {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		height: $features-tiles-item-header-index--radius;
		width: $features-tiles-item-header-index--radius;
		text-align: center;
		line-height: 50px;
	}

	.title {
		display: flex;
		justify-content: center;
		align-items: center;
		width: $features-tiles-item-header-title--width;
		height: $features-tiles-item-header-title--height;
		border-top-right-radius: $features-tiles-item--border-radius;
		border-bottom-right-radius: $features-tiles-item--border-radius;
		margin-left: -35px;
	}
}

.features-tiles-item-content {
	.tooltip {
		position: relative;
		padding: 10px 15px;
		background-color: #fbfaff;
		margin: 30px auto;
		border: 5px solid #fbfaff;
		text-align: center;
		border-radius: 20px;

		&.tooltip-top::before {
			content: '';
			position: absolute;
			display: block;
			width: 0px;
			left: 50%;
			top: 0;
			border: 15px solid transparent;
			border-top: 0;
			border-bottom: 15px solid #fbfaff;
			transform: translate(-50%, calc(-100% - 5px));
		}

		&.tooltip-bottom::before {
			content: '';
			position: absolute;
			display: block;
			width: 0px;
			left: 50%;
			bottom: 0;
			border: 15px solid transparent;
			border-bottom: 0;
			border-top: 15px solid #fbfaff;
			transform: translate(-50%, calc(100% + 5px));
		}

		h5 {
			@include font-weight(bquote);
		}

		p {
			@include font-size(xs);
		}
	}
}

.tile-1 {
	.features-tiles-item-header {
		.index {
			background: color-tile(tile-1) content-box;
		}

		.title {
			background-image: radial-gradient(circle at 0px 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50px, rgba(64, 45, 168, 1) 31px, rgba(64, 45, 168, 1) 100%);
		}
	}
}

.tile-2 {
	.features-tiles-item-header {
		.index {
			background: color-tile(tile-2) content-box;
		}

		.title {
			background-image: radial-gradient(circle at 0px 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50px, rgba(236, 236, 68, 1) 31px, rgba(236, 236, 68, 1) 100%);
		}
	}
}

.tile-3 {
	.features-tiles-item-header {
		.index {
			background: color-tile(tile-3) content-box;
		}

		.title {
			background-image: radial-gradient(circle at 0px 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50px, rgba(64, 45, 168, 1) 31px, rgba(64, 45, 168, 1) 100%);
		}
	}
}

.tile-4 {
	.features-tiles-item-header {
		.index {
			background: color-tile(tile-4) content-box;
		}

		.title {
			background-image: radial-gradient(circle at 0px 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50px, rgba(255, 104, 76, 1) 31px, rgba(255, 104, 76, 1) 100%);
		}
	}
}

.tile-5 {
	.features-tiles-item-header {
		.index {
			background: color-tile(tile-5) content-box;
		}

		.title {
			background-image: radial-gradient(circle at 0px 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50px, rgba(173, 55, 245, 1) 31px, rgba(173, 55, 245, 1) 100%);
		}
	}
}

.tile-6 {
	.features-tiles-item-header {
		.index {
			background: color-tile(tile-6) content-box;
		}

		.title {
			background-image: radial-gradient(circle at 0px 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50px, rgba(76, 208, 128, 1) 31px, rgba(76, 208, 128, 1) 100%);
		}
	}
}

@if ($tiles-items--padding__desktop !=null) {

	@include media('>medium') {

		.tiles-wrap {
			margin-right: -($tiles-items--padding__desktop * 0.5);
			margin-left: -($tiles-items--padding__desktop * 0.5);
			margin-top: -($tiles-items--padding__desktop * 0.5);

			&:last-of-type {
				margin-bottom: -($tiles-items--padding__desktop * 0.5);
			}

			&:not(:last-of-type) {
				margin-bottom: ($tiles-items--padding__desktop * 0.5);
			}

			&.push-left {

				&::after {
					padding-left: $tiles-items--padding__desktop * 0.5;
					padding-right: $tiles-items--padding__desktop * 0.5;
				}
			}
		}

		.tiles-item {
			padding: $tiles-items--padding__desktop * 0.5;
		}
	}
}

@include media('<medium') {
	.features-tiles-inner {
		.tiles-journey {
			display: none;
		}
	}

	.features-tiles-item-content {
		.tooltip {
			&.tooltip-bottom::before {
				bottom: unset;
				top: 0;
				border-top: 0;
				border-bottom: 15px solid #fbfaff;
				transform: translate(-50%, calc(-100% - 5px));
			}
		}
	}
}

@include media('>=medium') {
	.tiles-item-inner {
		&.reverse {
			flex-direction: column-reverse;
			justify-content: flex-end;

			.tooltip {
				margin-top: 0;
			}
		}
	}

	.features-tiles-item-content {
		.tooltip {
			margin: 90px auto;
		}
	}
}