[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

// Hero illustration
.illustration-section-01::after {
	@include illustration('illustration-section-01.svg', 100%, 100%, top, 40%, null);
}

@include media('>medium') {
	.illustration-three-lines::before {
		@include illustration('illustration-three-lines.svg', 20%, 100%, top, -53%, -30%);
	}

	.illustration-fearless-doer-1::before {
		@include illustration('illustration-fearless-doer-1.svg', 25%, 25%, top, -55%, 45%);
	}

	.illustration-fearless-doer-2::after {
		@include illustration('illustration-fearless-doer-2.svg', 25%, 25%, top, 30%, -10%);
	}

	.illustration-fearless-doer-3::after {
		@include illustration('illustration-fearless-doer-3.svg', 7%, 7%, bottom, 20%, 3%);
	}
}