.testimonial {
	margin-top: $testimonial--margin-t__mobile;
	margin-bottom: $testimonial--margin-b__mobile;
	background: get-color(primary, 1);
	position: relative;

	h1,
	h2,
	h3 {
		color: get-color(light, 1);
	}

	.ellipse-up,
	.ellipse-down {
		width: 100%;
		position: absolute;
	}

	.ellipse-up {
		top: -5%;
	}

	.ellipse-down {
		bottom: -5%;
	}

	.section-inner {
		padding-top: $testimonial--padding-t__mobile;
		padding-bottom: $testimonial--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $testimonial-header--padding__mobile;
	}

	.tiles-wrap {
		@if ($testimonial-items--padding__mobile !=null) {
			margin-right: -($testimonial-items--padding__mobile * 0.5);
			margin-left: -($testimonial-items--padding__mobile * 0.5);
			margin-top: -($testimonial-items--padding__mobile * 0.5);

			&:last-of-type {
				margin-bottom: -($testimonial-items--padding__mobile * 0.5);
			}

			&:not(:last-of-type) {
				margin-bottom: ($testimonial-items--padding__mobile * 0.5);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $testimonial-item--width;
				max-width: $testimonial-item--width;

				@if ($testimonial-items--padding__mobile !=null) {
					padding-left: $testimonial-items--padding__mobile * 0.5;
					padding-right: $testimonial-items--padding__mobile * 0.5;
				}
			}
		}
	}

	.owl-theme {
		position: relative;

		.owl-item {
			img {
				width: unset;
			}

			&.active {
				.testimonial-frame {
					display: block;
				}
			}
		}

		.owl-item.active {
			display: flex;
			justify-content: center;
		}

		.custom-nav {
			position: absolute;
			top: 40%;
			left: 0;
			right: 0;

			.owl-prev,
			.owl-next {
				position: absolute;
				height: 60px;
				width: 60px;
				background-color: get-color(secondary, 1);
				border: none;
				z-index: 100;
				cursor: pointer;


				i {
					font-size: 2.5rem;
					color: get-color(dark, 1);
				}
			}

			.owl-prev {
				left: 0;
			}

			.owl-next {
				right: 0;
			}
		}

	}

	.tiles-item {
		position: relative;
		flex-basis: $testimonial-item--width;
		max-width: $testimonial-item--width;
		margin: 40px 0;

		@if ($testimonial-items--padding__mobile !=null) {
			padding: $testimonial-items--padding__mobile * 0.5;
		}

		&>.testimonial-frame {
			display: none;
			position: absolute;
			z-index: -1;
			left: -30px;
			top: -35px;
		}
	}

	.tiles-item-inner {
		padding-top: $testimonial-item--inner-padding-v;
		padding-bottom: $testimonial-item--inner-padding-v;
		padding-left: $testimonial-item--inner-padding-h;
		padding-right: $testimonial-item--inner-padding-h;
	}
}

.testimonial-item-content {
	display: flex;
	justify-content: center;
	align-items: center;
	row-gap: 30px;
	flex-grow: 1;

	&>div {
		margin-right: 30px;
	}

	.testimonial-item-text {
		display: flex;
		flex-direction: column;

		&>* {
			margin-bottom: 30px;
		}

		.testimonial-item-name {
			display: flex;

			&>* {
				margin-right: 10px;
			}
		}
	}
}

.testimonial-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

@include media('<medium') {

	.ellipse-up,
	.ellipse-down {
		display: none;
	}

	.testimonial {
		.tiles-item {
			height: 640px;
		}

		.owl-theme {
			.custom-nav {
				display: none;
			}

			.owl-item {
				&.active {
					.testimonial-frame {
						display: none;
					}
				}
			}
		}
	}

	.testimonial-item-content {
		flex-direction: column-reverse;
	}
}

@include media('>medium') {

	.testimonial {

		.section-inner {
			padding-top: $testimonial--padding-t__desktop;
			padding-bottom: $testimonial--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $testimonial-header--padding__desktop;
		}

		@if ($testimonial-items--padding__desktop !=null) {

			.tiles-wrap {
				margin-right: -($testimonial-items--padding__desktop * 0.5);
				margin-left: -($testimonial-items--padding__desktop * 0.5);
				margin-top: -($testimonial-items--padding__desktop * 0.5);

				&:last-of-type {
					margin-bottom: -($testimonial-items--padding__desktop * 0.5);
				}

				&:not(:last-of-type) {
					margin-bottom: ($testimonial-items--padding__desktop * 0.5);
				}

				&.push-left {

					&::after {
						padding-left: $testimonial-items--padding__desktop * 0.5;
						padding-right: $testimonial-items--padding__desktop * 0.5;
					}
				}
			}

			.tiles-item {
				padding: $testimonial-items--padding__desktop * 0.5;
			}
		}
	}
}