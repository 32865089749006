.team {
    position: relative;

    .section-inner {
        padding-top: $team--padding-t__mobile;
        padding-bottom: $cta--padding-b__mobile;
    }

    .image-container {
        position: absolute;
        background-image: url(../../../images/team-shape.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        right: 0;
        top: -10%;
    }
}

.team-inner {
    .team-content {
        display: flex;
        align-items: stretch;

        &>* {
            flex-grow: 1;
            flex-basis: 0;
        }

        .team-text {
            margin-right: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        .team-cards {
            display: flex;
            flex-direction: column;

            &-row {
                display: flex;
                justify-content: center;

                &:last-child {
                    padding-top: 30px;
                }
            }

            .team-card {
                padding-right: 30px;

                &:last-child {
                    padding-right: 0;
                }

                &-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #EFEDFA;

                    h6 {
                        @include font-weight(strong);
                        color: get-color(primary, 1);
                    }
                }
            }
        }
    }
}

@include media('>medium') {

    .team {

        .section-inner {
            padding-top: $team--padding-t__desktop;
            padding-bottom: $team--padding-b__desktop;
        }

        .image-container {
            height: 720px;
            width: 600px;
        }
    }
}

@include media('<medium') {
    .team {
        .team-content {
            flex-direction: column;

            .team-text {
                margin-right: 0;
                align-items: flex-start;

                p {
                    text-align: left;
                }
            }

            .team-cards {
                flex-direction: row;

                .team-cards-row {
                    flex-direction: column;
                    padding-right: 30px;

                    &:last-child {
                        padding-right: 0;
                    }
                }

                .team-card {
                    padding-right: 0;
                    padding-bottom: 30px;

                    &:last-child {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}